import React from 'react'
import styled from 'styled-components'
import Section from '../components/Core/Layout/Section/Section'
import Layout from '../components/MarketingSite/Layout/Layout'

const FourOhFour = () => {
  return (
    <Layout seoTitle="404" seoDescription="This page was not found">
      <Section className="h-full flex justify-center items-center">
        <h1>Page does not exist</h1>
      </Section>
    </Layout>
  )
}

export default styled(FourOhFour)``
